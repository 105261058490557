import React, { useEffect, useState } from 'react';
import { Switch, Route, useHistory, useLocation } from 'react-router-dom';
import { PrivateRoute } from './PrivateRoute';
import { AppRoute } from './AppRoute';
import { useStore } from '../Store/UseStore';
import { LazyComponent } from '../Helpers/LazyComponent';
import { NO_SIDEBAR_URL, NO_TOPBAR_URL, NO_PROMOTION_URL, NO_FOOTER_URL } from '../Helpers/NavigationHelper';
import { ResponsiveView } from '../Components/Common/ResponsiveView';

import { getMobileOperatingSystem } from '../Helpers/CommonHelper';
import { ModalDispatcher } from '../Components/Modal/ModalDispatcher';
import { ToastDispatcher } from '../Components/Toast/ToastDispatcher';
import { ModalHelper } from '../Helpers/ModalHelper';
import { useWindowSize } from '../Helpers/Hooks/useWindowSize';
import { getResponsiveName } from '../Helpers/LayoutHelper';

const HomePage = LazyComponent(import('../Components/Home/HomePage'));
const SearchPage = LazyComponent(import('../Components/Search/SearchPage'));
const ProductPage = LazyComponent(import('../Components/ProductPage/ProductPage'));
const SocialLoginRedirectPage = LazyComponent(import('../Components/Auth/AuthItems/SocialLoginRedirectPage'));
const CartPage = LazyComponent(import('../Components/Checkout/Cart/CartPage'));
const DeliveryBillingPage = LazyComponent(import('../Components/Checkout/DeliveryBilling/DeliveryBillingPage'));
const PaymentPage = LazyComponent(import('../Components/Checkout/Payment/PaymentPage'));
const OrderSummaryPage = LazyComponent(import('../Components/Checkout/Order/OrderSummaryPage'));
const RecommendationPage = LazyComponent(import('../Components/Recommendation/RecommendationPage'));
const RecommendationDetailsPage = LazyComponent(import('../Components/Recommendation/RecommendationDetailsPage'));
const ProfilePage = LazyComponent(import('../Components/Profile/ProfilePage'));
const AboutPage = LazyComponent(import('../Components/About/AboutPage'));
const ContactPage = LazyComponent(import('../Components/Contact/ContactPage'));
const FAQPage = LazyComponent(import('../Components/FAQ/FAQPage'));
const FinePrintPage = LazyComponent(import('../Components/FinePrint/FinePrintPage'));
const PrivacyPage = LazyComponent(import('../Components/FinePrint/Privacy/PrivacyPage'));
const CookiePage = LazyComponent(import('../Components/FinePrint/Cookie/CookiePage'));
const TermsPage = LazyComponent(import('../Components/FinePrint/Terms/TermsPage'));
const RefundPage = LazyComponent(import('../Components/FinePrint/Refund/RefundPage'));
const AccountDeletionPage = LazyComponent(import('../Components/AccountDeletion/AccountDeletionPage'));
const AccountDeletionConfirmPage = LazyComponent(import('../Components/AccountDeletion/AccountDeletionConfirmPage'));
const SavedProductsPage = LazyComponent(import('../Components/SavedProducts/SavedProductsPage'));
const OtherInfoPage = LazyComponent(import('../Components/Profile/OtherInfoPage'));
const NotFoundPage = LazyComponent(import('../Components/NotFound/NotFoundPage'));

const TopBar = LazyComponent(import('../Components/Common/TopBar/TopBar'));
const SideMenu = LazyComponent(import('../Components/Menu/SideMenu/SideMenu'));
const Footer = LazyComponent(import('../Components/Common/Footer/Footer'));
const Promotion = LazyComponent(import('../Components/Common/Promotion/Promotion'));
const BottomMenu = LazyComponent(import('../Components/Menu/BottomMenu/BottomMenu'));

const TestDragPage = LazyComponent(import('../Components/TestDrag/TestDragPage'));

const AdminRouter = LazyComponent(import('../Components/Admin/AdminRouter/AdminRouter'));

export const AppRouter = () => {
  const store = useStore();
  const history = useHistory();
  const location = useLocation();
  const { width } = useWindowSize();

  const [sidebar, showSidebar] = useState(true);
  const [topbar, showTopbar] = useState(true);
  const [promo, showPromo] = useState(true);
  const [footer, showFooter] = useState(true);

  useEffect(() => {
    showSidebar(NO_SIDEBAR_URL.indexOf(location.pathname) < 0);
    showTopbar(NO_TOPBAR_URL.indexOf(location.pathname) < 0);
    showPromo(NO_PROMOTION_URL.indexOf(location.pathname) < 0);
    showFooter(NO_FOOTER_URL.indexOf(location.pathname) < 0);
  }, [location]);

  return (
    <>
      {sidebar && (
        <ResponsiveView phoneStyle={{ display: 'none' }}>
          <SideMenu />
        </ResponsiveView>
      )}
      {topbar && <TopBar />}
      {promo && <Promotion />}
      <ResponsiveView
        id="wd-content-view"
        webStyle={sidebar ? styles.containerWithSidebar : styles.containerWithoutSidebar}
        phoneStyle={styles.phoneContainer}>
        <Switch>
          <Route path="/" exact component={HomePage} />
          <Route path="//" exact component={HomePage} />
          <Route path="/home" exact component={HomePage} />
          <Route path="/index.html" exact component={HomePage} />
          <Route path="/New-Arrival-Wines" exact component={HomePage} />
          <Route path="/Wines-On-Sale" exact component={HomePage} />
          <Route path="/Express-Wines" exact component={HomePage} />
          {/* <Route path="/Picked-For-You" exact component={HomePage} /> */}

          <Route path="/search" component={SearchPage} />
          <Route path="/Buy-Wine-Online/:subPage" component={SearchPage} />
          <Route path="/Buy-Wine-Online" component={SearchPage} />

          <Route path="/product/:sku/:name" component={ProductPage} key={window.location?.pathname} />
          <Route path="/product/:sku" component={ProductPage} />

          <Route path="/signUp" component={getResponsiveName(width) === 'phone' ? ProfilePage : HomePage} />
          <Route path="/logIn" component={getResponsiveName(width) === 'phone' ? ProfilePage : HomePage} />
          <Route path="/sociallogin/:type" component={SocialLoginRedirectPage} />
          <Route
            path="/customer/account/createPassword/"
            component={getResponsiveName(width) === 'phone' ? ProfilePage : HomePage}
          />
          <Route path="/customer/account/accountDeletion" component={AccountDeletionPage} />
          <Route path="/customer/account/confirmAccountDeletion" component={AccountDeletionConfirmPage} />

          <Route path="/cart" component={CartPage} />
          <Route path="/checkout" component={DeliveryBillingPage} />
          <Route path="/payment-method" component={PaymentPage} />
          <Route path="/order-summary" component={OrderSummaryPage} />

          <Route path="/recommendations/:id/:name" component={RecommendationDetailsPage} />
          <Route path="/recommendations" component={RecommendationPage} />

          <Route path="/profile/:tab" component={ProfilePage} />
          <Route path="/profile" component={ProfilePage} />

          <Route path="/about" component={AboutPage} />
          <Route path="/contact" component={ContactPage} />
          <Route path="/faq" component={FAQPage} />
          <Route path="/fine-print" component={FinePrintPage} />
          <Route path="/privacy" component={PrivacyPage} />
          <Route path="/cookie" component={CookiePage} />
          <Route path="/terms" component={TermsPage} />
          <Route path="/refund" component={RefundPage} />
          <Route path="/other-info" component={OtherInfoPage} />

          <Route path="/saved" component={SavedProductsPage} />

          <Route path="/test-drag" component={TestDragPage} />

          <Route path="/admin/*" exact={true} component={AdminRouter} />

          <Route component={NotFoundPage} />

          <PrivateRoute component={AppRoute} />
        </Switch>
      </ResponsiveView>
      <BottomMenu />
      {footer && <Footer />}
      <ModalDispatcher ref={(ref) => (ModalHelper.dispatcher = ref)} />
      <ToastDispatcher ref={(ref) => (ModalHelper.toastDispatcher = ref)} />
    </>
  );
};

const styles = {
  containerWithSidebar: {
    paddingLeft: 60,
  } as React.CSSProperties,
  containerWithoutSidebar: {
    paddingLeft: 0,
  } as React.CSSProperties,
  phoneContainer: {
    paddingBottom: getMobileOperatingSystem() === 'iOS' ? 75 : 55,
  } as React.CSSProperties,
};
